/* mobile menu function */
export const heightFix = () => {
    const appHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    appHeight();
    window.addEventListener('resize', appHeight);
}
export const headerFix = () => {
    const headerHeight = () => {
        const doc = document.documentElement,
            height = document.querySelector('.header').offsetHeight;
            doc.style.setProperty('--header-height', `${height}px`);
            $('.header').next().css('margin-top', `${height + 10}px`);
    }
    headerHeight();
}
