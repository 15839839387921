// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset.js';
import 'lazysizes/plugins/respimg/ls.respimg.js';
import 'lazysizes/plugins/parent-fit/ls.parent-fit.js';

// modal
import intlTelInput from 'intl-tel-input';
import Swiper, {Pagination, Keyboard, Navigation, Lazy, Autoplay} from "swiper";
import Choices from "choices.js";
import GreenAudioPlayer from "green-audio-player/dist/js/green-audio-player.js";

// functions
import { heightFix, headerFix } from './_func/_nojq/heightFix.js';
import { hamburger } from './_func/_jq/humburger.js';

/* Cookies */
// set
function setCookie(name, value, options = {}) {
	options = {
		path: '/'
	};
	if (options.expires instanceof Date) {
		options.expires = options.expires.toUTCString();
	}
	let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
	for (let optionKey in options) {
		updatedCookie += "; " + optionKey;
		let optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += "=" + optionValue;
		}
	}
	document.cookie = updatedCookie;
}
// get
function getCookie(name) {
	let matches = document.cookie.match(new RegExp(
		"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
	));
	return matches ? decodeURIComponent(matches[1]) : undefined;
}
// check
if(getCookie('h_info') && $('.header .header__top').length > 0){
	$('.header .header__top').addClass('removed').css('display', 'none');
}


document.addEventListener('DOMContentLoaded', () => {
    /* modile height fix */
    heightFix();

	/* change to mobile menu */
	function change_menu(){
		if(window.innerWidth >= 1200){
			if($('.header__main .mmenu .personal-block').length > 0){
				$('.header__main .profile-links').append($('.header__main .mmenu .personal-block'));
			}
		}
		else{
			$('.header__main .mmenu').append($('.header__main .profile-links .personal-block'));
		}
	}
	change_menu();
	window.addEventListener('resize', change_menu);

	/* header height */
	headerFix();

	/* header scroll */
	$(window).scroll(function() {
		var scroll = $(this).scrollTop();
		if(scroll > 0) {
			$('.header').addClass('scroll');
            if($('.header .header__top').is(':visible')){
                $('.header .header__top').slideUp(400);
            }
		} else {
			$('.header').removeClass('scroll');
            if(!$('.header .header__top').hasClass('removed')){
                $('.header .header__top').slideDown(400);
            }
		}
		if(scroll > 900 && window.innerWidth > 1024){
			$('.totop_btn').fadeIn();
		}
		else{
			$('.totop_btn').fadeOut();
		}
	});

	/* toTop btn */
	$('.totop_btn').click(function (){
		$('html,body').stop().animate({ scrollTop: 0 }, 1000);
	});

	/* header info close function */
	$('.header').on('click', '.header-close', function (){
		$('.header .header__top').slideUp(400).addClass('removed');
		// set choose to cookie for 1 day
		setCookie('h_info', true, {secure: true, 'max-age': 86400});
		setTimeout(function (){
			headerFix();
		},400);
	});

	/* mobile menu */
	hamburger('hamburger');

	/* mobile submenu */
	$('.mmenu-list__sublink').click(function (){
		$(this).parent().find('.submenu-list').slideToggle('fast')
		$(this).parent().toggleClass('active');
	});

	/* main page */
	// main slider
	var mainSlider = new Swiper('#mslider', {
		modules: [ Pagination, Lazy, Autoplay ],
		lazy: true,
		speed: 800,
		slidesPerView: 1,
		spaceBetween: 30,
		loop: true,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true
		},
		pagination: {
			el: '.main-pagination',
			clickable: true,
		},
		breakpoints: {
			768: {
				direction: 'vertical'
			}
		}
	});

	// choose select
	$('.js-choose-select').change(function (){
		var curTab = $(this).find('option:selected').val();
		$('.curses-block__tabs__item').removeClass('active');
		$('.curses-block__tabs__item[data-tab="'+ curTab +'"]').addClass('active');
	});

	// cards slider
	const cardsSlider = new Swiper('#cards-slider', {
		modules: [ Navigation, Lazy ],
		lazy: true,
		speed: 800,
		slidesPerView: 'auto',
		spaceBetween: 0,
		centeredSlides: true,
		allowTouchMove: true,
		navigation: {
			nextEl: '.cards-block .slider-btn.next-slide',
			prevEl: '.cards-block .slider-btn.prev-slide',
		}
	});

	// answers slider
	const answersSlider = new Swiper('#answers-slider', {
		modules: [ Navigation, Lazy ],
		lazy: true,
		speed: 800,
		slidesPerView: 'auto',
		spaceBetween: 0,
		allowTouchMove: true,
		navigation: {
			nextEl: '.answers-block .slider-btn.next-slide',
			prevEl: '.answers-block .slider-btn.prev-slide',
		}
	});

	/* learning page */
	// menu check for learning page
	function menu_check(){
		var scroll_top = $(document).scrollTop(),
			footer_offset = $('footer').position().top;
		$('.section-navigation .navigation-list .navigation-list__item').each(function (){
			var target = $('.'+ $(this).attr('data-section') + '');
			if (target.position().top - document.querySelector('.header__main').offsetHeight  <= scroll_top) {
				$('.section-navigation .navigation-list .navigation-list__item.active').removeClass("active");
				$(this).addClass("active");
			} else {
				$(this).removeClass("active");
			}
		});
		if(scroll_top >= footer_offset){
			$('.section-navigation').removeClass('show');
		}
		else{
			$('.section-navigation').addClass('show');
		}
	}
	function section_nav(){
		if($('.section-navigation').length > 0){
			if(window.innerWidth >= 1200){
				$('.section-navigation .navigation-list').html('');
				$('<li class="navigation-list__item" data-section="'+ $('main').attr('class') +'"></li>').appendTo($('.section-navigation .navigation-list'));
				$('section').each(function (){
					$('<li class="navigation-list__item" data-section="'+ $(this).attr('class') +'"></li>').appendTo($('.section-navigation .navigation-list'));
				});
				menu_check();
				setTimeout(function (){
					$('.section-navigation').addClass('show');
				},500)
				$(document).on("scroll", menu_check);
				$('.section-navigation .navigation-list .navigation-list__item').click(function (){
					var target = $('.'+ $(this).attr('data-section') + ''),
						header_height = document.querySelector('.header__main').offsetHeight;
					$("html, body").animate({
						scrollTop: target.offset().top - header_height
					});
				});
			}
			else{
				$('.section-navigation .navigation-list').html('');
			}
		}
	}
	section_nav();
	window.addEventListener('resize', section_nav);

	// learning team btns function
	$('.learn-team .track-btn').click(function (){
		$(this).parents('.content-wrapper').find('.track-wrapper').slideToggle('fast');
		if($(this).hasClass('active')){
			$(this).text($(this).attr('data-close'));
			$(this).removeClass('active');
		}
		else{
			$(this).text($(this).attr('data-open'));
			$(this).addClass('active');
		}
	});

	// learning page sliders
	function progSlider_init(){
		let programmSlider;
		if(window.innerWidth >= 1200){
			$('#programm-slider .pslider-wrapper').removeClass('swiper-wrapper').removeAttr('style');
			$('#programm-slider .programm-pagination').html('');
		}
		else{
			$('#programm-slider .pslider-wrapper').addClass('swiper-wrapper');
				setTimeout(function () {
					programmSlider = new Swiper('#programm-slider', {
					modules: [Pagination, Lazy],
					lazy: true,
					speed: 800,
					slidesPerView: 1,
					spaceBetween: 20,
					loop: false,
					pagination: {
						el: '.learn-programm__content .programm-pagination',
						clickable: true,
					},
					breakpoints: {
						1200: {
							allowTouchMove: false
						}
					}
				});
				},300);
			}
	}
	progSlider_init();
	window.addEventListener('resize', progSlider_init);

	// learning page questions
	$('.learn-questions').on('click', '.learn-questions__content__item .item-title', function (){
		$(this).parent().toggleClass('active');
		$(this).siblings('.item-descr').slideToggle(300);
	});

	// learning page audio modal
	GreenAudioPlayer.init({
		selector: '.audio-block',
		stopOthersOnPlay: true,
		showDownloadButton: false,
		showTooltips: false
	});
	$('.track-descr__person .audio-link').click(function (){
		$(this).parents('.track-descr__person').find('.play-pause-btn').click();
	});
	$('.play-pause-btn').click(function (){
		var btn = $(this).parents('.person-modal').attr('id');
		$('.audio-link[data-modal="'+ btn +'"]').toggleClass('play-type');
	})
	// modal show text
	$('.message-show').on('click','.show-btn', function (){
		$(this).parents('.info-item').find('.message-block').toggleClass('show');
		$(this).toggleClass('open');
	})

	/* inputs / selects */
	// mask for name (without numbers)
	$('input.name-input').keypress(function (key) {
		if (key.charCode < 48 || key.charCode > 57) return true;
		return false;
	});
	// disable submit btn check
	$('input.check-input').change(function (){
		if($(this).prop('checked')){
			$(this).parents('.check-group').siblings('.btn-group').find('button').prop('disabled', false);
		}
		else{
			$(this).parents('.check-group').siblings('.btn-group').find('button').prop('disabled', true);
		}
	});
	// custom select
	document.querySelectorAll('select.custom-select').forEach(s=>{
		const customSelects = new Choices(s, {
			searchEnabled: false,
			itemSelectText: ''
		});
	});
	// phone select
	document.querySelectorAll('.phone-input').forEach(input => {
		var phoneInput = intlTelInput(input, {
			utilsScript: 'js/utils.js',
			separateDialCode: true,
			defaultCountry: 'auto',
			preferredCountries: ['ru'],
			customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
				return "Телефон";
			}
		});
	});

	/* footer */
	// footer menu
	$('.footer__top__column .submenu-link').click(function (){
		$(this).parent().toggleClass('show');
		$(this).siblings('ul').slideToggle('fast');
	});

	/* modals */
	$('.js-video-link').magnificPopup({
		type: 'iframe',
		mainClass: 'my-mfp-zoom-in',
		removalDelay: 160,
		preloader: false,
		callbacks: {
			open: function() {
				$('body').addClass('overflow');
			},
			close: function() {
				$('body').removeClass('overflow');
			}
		}
	});
	$('.js-image-link').magnificPopup({
		type: 'image',
		mainClass: 'my-mfp-zoom-in',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: true,
		fixedBgPos: true,
		image: {
			verticalFit: true
		},
		callbacks: {
			open: function() {
				$('body').addClass('overflow');
			},
			close: function() {
				$('body').removeClass('overflow');
			}
		}
	});
	$('.js-modal-link').magnificPopup({
		type: 'inline',
		fixedContentPos: true,
		fixedBgPos: true,
		overflowY: 'auto',
		closeBtnInside: true,
		preloader: false,
		midClick: true,
		removalDelay: 300,
		mainClass: 'my-mfp-zoom-in',
		callbacks: {
			open: function() {
				$('body').addClass('overflow');
			},
			close: function() {
				$('body').removeClass('overflow');
			}
		}

	});
	$(document).on('click', '.modal-close', function (e) {
		e.preventDefault();
		if($(this).attr('data-player') == 'stop'){
			if($(this).parent().find('.play-pause-btn').attr('aria-label') == 'Pause'){
				$(this).parent().find('.play-pause-btn').click();
			}
		}
		$.magnificPopup.close();
	});
	$('.js-images-gallery').magnificPopup({
		delegate: 'a.js-gallery-image',
		type: 'image',
		tLoading: '',
		mainClass: 'my-mfp-zoom-in',
		fixedContentPos: true,
		fixedBgPos: true,
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1],
			tCounter: '<span class="mfp-counter">%curr% из %total%</span>' // markup of counter
		},
		image: {
			tError: 'Error'
		},
		callbacks: {
			open: function() {
				$('body').addClass('overflow');
			},
			close: function() {
				$('body').removeClass('overflow');
			}
		}
	});
});
